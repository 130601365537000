import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { api } from '../../api/api';
import { AsyncThunkState } from '../store';
import { Product } from '../../types/general';
import { initialCategoryId } from './config';

type CatalogState = {
  products: AsyncThunkState<Product[]>;
  filters: {
    category: string;
    stores: string[];
  };
};

const initialState: CatalogState = {
  products: {
    isLoading: false,
    data: [
      { id: '1', name: 'Левый AirPods', price: 1, images: [{ preview: 'https://ilostmyearbud.com/wp-content/uploads/2020/10/left_earpod_main.jpg', url: 'https://ilostmyearbud.com/wp-content/uploads/2020/10/left_earpod_main.jpg'}], sum_stocks_in_city: 100 },
      { id: '2', name: 'Правый AirPods', price: 1, images: [{ preview: 'https://ilostmyearbud.com/wp-content/uploads/2020/10/right_earpod_main.jpg', url: 'https://ilostmyearbud.com/wp-content/uploads/2020/10/right_earpod_main.jpg'}], sum_stocks_in_city: 99 },
      { id: '3', name: 'Чехол', price: 1, images: [{ preview: 'https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MR8U2_AV3?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1551489694816', url: 'https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MR8U2_AV3?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1551489694816' }], sum_stocks_in_city: 99 },
    ],
    error: {},
  },
  filters: {
    category: initialCategoryId,
    stores: [],
  },
};

export const fetchProducts = createAsyncThunk<any, CatalogState['filters']>(
  'catalog/fetchProducts',
  async (filters) => {
    return api
      .get(`/products`, {
        params: filters,
      })
      .then((response) => response.data)
      .catch((error) => error);
  },
);

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    checkFilterStore(state, action: PayloadAction<string>) {
      if (state.filters.stores.includes(action.payload)) {
        state.filters.stores = state.filters.stores.filter(
          (id) => id !== action.payload,
        );
      } else {
        state.filters.stores.push(action.payload);
      }
    },
    clearFilterStore(state) {
      state.filters.stores = initialState.filters.stores;
    },

    selectFilterCategory(state, action: PayloadAction<string>) {
      state.filters.category = action.payload;
    },
  },
  extraReducers: {
    [fetchProducts.pending.type]: (state, action) => {
      state.products = {
        isLoading: true,
        data: [],
        error: {},
      };
    },
    [fetchProducts.fulfilled.type]: (state, action) => {
      state.products = {
        isLoading: false,
        data: action.payload,
        error: {},
      };
    },
    [fetchProducts.rejected.type]: (state, action) => {
      state.products = {
        isLoading: false,
        data: [],
        error: action.payload,
      };
    },
  },

});


export const { checkFilterStore, clearFilterStore, selectFilterCategory } = catalogSlice.actions;

// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const selectProducts = (state: RootState) => state.catalog.products;
export const selectFilterStores = (state: RootState) => state.catalog.filters.stores;
export const selectFilters = (state: RootState) => state.catalog.filters;
//
